import React, { useContext, useEffect } from "react"
import styled from "styled-components"
import PrimaryLayout from "@src/layouts/primary"
import { UserContext } from "@src/context/user-context"
import { LocaleContext } from "@src/context/locale-context"
import PageHelmet from "@src/components/page-helmet"
import { normalizeTitle } from "@src/utils/page-data"
import ResponsiveSection from "@src/components/container-responsive-section"
import FormFieldInputUnitsSwitch from "@src/components/form-field-input-units-switch"
import FormFieldInputText from "@src/components/form-field-input-text"
import FormFieldInputCurrency from "@src/components/form-field-input-currency"
import Form, { FormGroup, FormActions } from "@src/components/form"
import Button from "@src/components/core-button"
import Link, { navigate } from "@src/components/core-link"
import Spinner from "@src/components/core-spinner"

const UserInfo = styled.dl`
  & > div {
    display: flex;
    flex-flow: row wrap;
    margin-bottom: 10px;
    & > dt {
      width: 110px;
      grid-column: 1 / 2;
      font-weight: bold;
      margin-right: 10px;
    }
    & > dd {
      grid-column: 2 / 3;
    }
  }
`
const StyledFormFieldInputCurrency = styled(FormFieldInputCurrency)`
  width: 209px;
  @media only screen and (${props => props.theme.screen.small.min}) {
    width: 213px;
  }
`
const UpdateActions = styled(FormActions)`
  align-items: flex-start;
`
const UserProfilePage = () => {
  const { user, updateUserProfile } = useContext(UserContext)
  const { units, setPreferredUnits, currency, setPreferredCurrency } =
    useContext(LocaleContext)

  const handleSubmit = values => {
    const profileChanges = {}
    if (values["user-display-name"]) {
      profileChanges["displayName"] = values["user-display-name"]
    }
    updateUserProfile(profileChanges)
  }
  const handleCurrencyChange = currency => {
    const currencyValue =
      currency.value === "Automated" ? undefined : currency.value
    setPreferredCurrency(currencyValue)
  }
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (user) return
      navigate("/")
    }, 3000)
    return () => clearTimeout(timeoutId)
  }, [user])
  return (
    <PrimaryLayout>
      <PageHelmet title={normalizeTitle("User Profile")} />
      {user ? (
        <ResponsiveSection align="left">
          <h1 className="fancy">{`Welcome${
            user && user.profile
              ? ` ${
                  user.profile.displayName
                    ? user.profile.displayName
                    : user.profile.email
                }!`
              : "!"
          }`}</h1>
          <p>
            These details are displayed next to your publicly shared reviews,
            ratings, photos, etc.
          </p>
          <UserInfo>
            <div>
              <dt>Email</dt>
              <dd>{user?.profile?.email ? user.profile.email : "N/A"}</dd>
            </div>
            <div>
              <dt>Display Name</dt>
              <dd>
                {user?.profile?.displayName ? user.profile.displayName : ""}
              </dd>
            </div>
          </UserInfo>
          <h2 className="fancy">Update Info</h2>
          <p>
            Any updates you make here will also appear in past contributions.
          </p>
          <Form name="user-info-update" onSubmit={handleSubmit}>
            <FormGroup column>
              <FormFieldInputText
                id="user-display-name"
                name="user-display-name"
                label="Display Name"
                placeholder={user?.profile?.displayName}
              />
            </FormGroup>

            <UpdateActions>
              <Button type="submit">Update Personal Info</Button>
            </UpdateActions>
          </Form>
          <h2 className="fancy">Update Password</h2>
          <Link
            to={`/account/recovery/${
              user?.profile?.email
                ? "?email=" + encodeURIComponent(user?.profile?.email)
                : ""
            }`}
          >
            Click here to reset your password!
          </Link>

          <h2 className="fancy">Preferences</h2>
          <FormFieldInputUnitsSwitch
            id="user-profile-units"
            name="units"
            label="Preferred Units"
            labelType="long"
            value={units}
            onChange={setPreferredUnits}
          />
          <StyledFormFieldInputCurrency
            id="user-profile-currency"
            name="currency"
            label="Preferred Currency"
            labelType="long"
            value={currency?.code || "Automated"}
            onChange={handleCurrencyChange}
          />
        </ResponsiveSection>
      ) : (
        <ResponsiveSection variant="card">
          <h1>Must be logged in to view this page!</h1>
          <p>Redirecting to the home page.</p>
          <p>
            <Link to="/account/login/">Click here to log in!</Link>
          </p>
          <Spinner align="center" />
        </ResponsiveSection>
      )}
    </PrimaryLayout>
  )
}
export default UserProfilePage
