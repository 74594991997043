import React, { useState, useCallback } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import InputDropdown from "@src/components/input-dropdown"
import { useCurrencies } from "@src/hooks/useCurrencies"
const CurrencyDropdown = styled(InputDropdown)``

const InputCurrency = ({ className, onChange, ...props }) => {
  const [currency, setCurrency] = useState()
  const currenciesList = useCurrencies()
  const currencyOptions = [{ text: "Automated", value: "Automated" }]

  const handleCurrencyChange = useCallback(
    value => {
      setCurrency(value)
      if (onChange) {
        onChange(value)
      }
    },
    [setCurrency, onChange]
  )

  if (currenciesList && currenciesList.length) {
    currenciesList.forEach(currency =>
      currencyOptions.push({
        text: `${currency.symbol ? currency.symbol + " " : ""}${currency.code}`,
        value: currency.code,
      })
    )
  }

  return (
    <CurrencyDropdown
      className={className}
      value={currency}
      onChange={handleCurrencyChange}
      options={currencyOptions}
      {...props}
    />
  )
}

InputCurrency.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
}
export default InputCurrency
